
import { Component, Vue } from 'vue-property-decorator'
import { MapBaseDetail } from '@/types/rareTrees'
import { DicInfo, DicList } from '@/types/common'
import AMapLoader from '@amap/amap-jsapi-loader'
import { mapKey } from '@/utils/config'
// import { KeepAlive } from '@/utils/decorators'
@Component({
  name: 'RareTreesMap'
})
// @KeepAlive
export default class RareTreesMap extends Vue {
  private searchInfo = {
    plantName: '',
    projectId: '',
    isFamous: '0',
    seedlingType: '',
    treeAge: ''
  }

  private projectList = []

  private typeList = []
  private date = []

  private isMapLoad = false
  private map: any = null
  private icon = require('@/assets/icon/resourceManage/icon_gushumingmu_zaixian.svg')
  private iconAlarm = require('@/assets/icon/resourceManage/icon_gushumingmu_gaojing.svg')

  created () {
    // console.log('初始化')
    this.loadMap().then(() => {
      this.isMapLoad = true
      this.getProtectList()
    })
    this.getTypeList()
    // this.getData()
  }

  // 查询苗木类别列表
  getTypeList () {
    this.$axios.get(this.$apis.record.selectSeedlingTypeByList).then(res => {
      this.typeList = res.list || []
    })
  }

  destroyed () {
    // console.log('销毁地图')
    if (this.map) {
      this.map.off('click')
      this.map.destroy()
    }
  }

  // 查询
  searchData () {
    this.getData([], true)
  }

  // 获取含有苗木的项目列表
  getProtectList () {
    this.$axios.get(this.$apis.seedling.selectProjectList).then((res) => {
      // console.log('res32', res)
      this.projectList = res || []
      if (res.length) {
        const centerPosition = new AMap.LngLat(Number(res[0].longitude), Number(res[0].latitude))
        this.searchInfo.projectId = res[0].projectId
        this.getData(centerPosition)
      } else {
        // this.getData()
        this.$message.success('暂无项目，请先建立项目')
      }
    })
  }

  // 渲染地图
  loadMap () {
    return AMapLoader.load({
      key: mapKey,
      version: '2.0',
      plugins: []
    })
  }

  drawMarks (list: MapBaseDetail[]) {
    this.map.clearMap()
    list.forEach(item => {
      const statusInfo = this.getIconAndStatus(item)
      // 地图打点
      const marker = new AMap.Marker({
        position: new AMap.LngLat(+item.longitude, +item.latitude),
        icon: new AMap.Icon({
          // 图标的取图地址
          image: statusInfo.icon
        }),
        offset: new AMap.Pixel(-21, -43),
        label: {
          content: `<div class='map_tips'><div class="${statusInfo.status}"></div>&nbsp;${item.plantName}</div>`,
          offset: new AMap.Pixel(0, -10),
          direction: 'top'
        },
        extData: item.seedlingId
      })
      marker.on('click', (e) => {
        this.goDetail(e.target.getExtData())
      })
      this.map.add(marker)
    })
  }

  getIconAndStatus (detail: MapBaseDetail) {
    return detail.runState === '1' ? {
      icon: this.iconAlarm,
      status: 'alarm'
    } : {
      icon: this.icon,
      status: 'normal'
    }
  }

  // 获取表格数据
  getData (centerPosition?: any, isSearch?: any) {
    console.log('centerPosition', centerPosition)
    this.$axios.get<Array<MapBaseDetail>>(this.$apis.seedling.selectSeedlingByList, {
      ...this.searchInfo,
      startDate: this.date ? (this.date[0] || '') : '',
      endDate: this.date ? (this.date[1] || '') : ''
    }).then((res) => {
      // console.log('res', res)
      if (res && res.length > 0) {
        const center = new AMap.LngLat(Number(res[0].longitude), Number(res[0].latitude))
        // 如果地图插件已加载，正常情况下，地图插件加载比数据拉去快速，但是这里价格判断，更严谨
        if (this.isMapLoad) {
          // 判断地图是否已绘制
          if (this.map) {
            this.map.setCenter(center)
          } else {
            this.map = new AMap.Map('map', { // 设置地图容器id
              zoom: 14,
              center: center
            })
          }
          this.drawMarks(res)
        } else {
          this.loadMap().then(() => {
            this.map = new AMap.Map('map', { // 设置地图容器id
              zoom: 14,
              center: center
            })
            this.drawMarks(res)
          })
        }
      } else {
        // 查询不到数据, 不移动地图
        if (!isSearch) {
          this.map = new AMap.Map('map', { // 设置地图容器id
            zoom: 14,
            center: centerPosition
          })
        }
        if (this.isMapLoad) {
          if (this.map) {
            this.map.clearMap()
          }
        }
        this.$message.success('暂未查询到数据信息')
      }
    })
  }

  goDetail (seedlingId: string) {
    this.$router.push({
      path: `/ordinarySeedlingList/map/detail${seedlingId}`
    })
  }
}
